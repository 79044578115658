import React from 'react'
import ImageWrap from 'components/ImageWrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, Parallax } from 'swiper'

SwiperCore.use([Pagination, Autoplay, Parallax])

export default function ProductSlideshowBlock({ content }) {
  const { products, featuredCollectionImage } = content
  return (
    <section className='product-slideshow-block'>
      <div className='slideshow'>
        <Swiper
          slidesPerView={1}
          speed={800}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          parallax={true}
        >
          {products.map((product, i) => (
            <SwiperSlide key={i}>
              <Slide content={product} />
            </SwiperSlide>
          ))}
          <div className='swiper-pagination' />
        </Swiper>
      </div>
      <div className='image'>
        <div className='inner'>
          <ImageWrap image={featuredCollectionImage} />
        </div>
      </div>
    </section>
  )
}

const Slide = ({ content }) => {
  const { image, title, excerpt } = content
  return (
    <div className='slide container'>
      {image && (
        <div className='image' data-swiper-parallax='120%'>
          <ImageWrap image={image} />
        </div>
      )}
      <div className='text'>
        <h2 data-swiper-parallax='130%'>{title}</h2>
        {excerpt && <p data-swiper-parallax='110%'>{excerpt}</p>}
      </div>
    </div>
  )
}
