import React from 'react'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import BannerBlock from 'components/blocks/BannerBlock'
import ProductSlideshowBlock from 'components/blocks/ProductSlideshowBlock'

export default function FeaturedCollection({
  content,
  allCollections,
  isRelatedCollection = false,
  showSlideshow = true,
  mobileBgBeige = false,
}) {
  const { locale } = useLocalization()
  const featuredCollection = content?.featuredCollection
  if (!featuredCollection) return false
  const featuredCollectionFull = allCollections.find(
    (allCollection) => allCollection.slug === featuredCollection.slug
  )

  let { bannerBlock } = featuredCollectionFull
  if (isRelatedCollection) {
    bannerBlock.pretitle = tr('relatedCollection', locale)
  }

  return (
    <>
      <BannerBlock content={bannerBlock} mobileBgBeige={mobileBgBeige} />
      {showSlideshow && (
        <ProductSlideshowBlock content={featuredCollectionFull} />
      )}
    </>
  )
}
